import React from 'react';
import "../CSS/App.css"

const Disclaimer = () => {

    const disclaimer_txt = "StockRisk does not take any responsibility for investments made using information provided by us. While we strive for accuracy, errors may occur in both our equations and the data we use. Therefore, using our tools for your investment decisions is entirely at your own risk. We recommend seeking professional financial advice before making any investment decisions based on our information."

    return (
    <div className='App'>
        <h2>Disclaimer</h2>
        <p>{disclaimer_txt}</p>
    </div>
    );
};

export default Disclaimer;
