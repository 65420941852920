import React from 'react';
import "../CSS/App.css"

const About = () => {

  const about_txt = "StockRisk is a Norwegian based company aimed at providing investement tools to amataur investors! "

  return (
    <div className='App'>
      <h2>About StockRisk</h2>
      <p>{about_txt}</p>
    </div>
  );
};

export default About;
