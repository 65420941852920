// store.js
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

//This constant handles the initial state of all the objects we are going to use!
const initialState = {
  user: {
    name: '', // Initial name is an empty string
  },
  portfolio: {
    stocks: {},
  },
  availableStocks: {
    AWSStocklist: {},
  },
  statistics : {
    PortfolioStatistics: {},
  }
};

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case 'SET_USER_NAME':
      return {
        ...state,
        name: action.payload,
      };
    default:
      return state;
  }
};

const stocklistReducer = (state = initialState.availableStocks, action) => {
  switch (action.type) {
    case 'GET_AWS_STOCK_LIST':
      return {
        ...state,
        AWSStocklist: action.payload
      };
    default:
      return state;    
  }
}

const portfolioReducer = (state = initialState.portfolio, action) => {
  switch (action.type) {
    case 'ADD_TO_PORTFLOIO':
      return {
        ...state,
        stocks: action.payload
      };
    default:
      return state;
  }
}

const statisticsReducer = (state = initialState.statistics, action) => {
  switch (action.type) {
    case 'SET_PORTFOLIO_STATISTICS':
      return {
        ...state,
        PortfolioStatistics: action.payload
      };
    default:
      return state;
  }
}


const rootReducer = combineReducers({
  user: userReducer,
  portfolio: portfolioReducer,
  availableStocks: stocklistReducer,
  statistics: statisticsReducer
})

const store = configureStore({
  reducer: rootReducer
});

export default store;
