// This scirpt is used for handling all the statistics from the stored statistic

// React imports
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// Boot strap functions ----------------
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Chart imports --------------
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

import '../CSS/Statistics.css'

const StockRisk = () => {

  const [state_portfolio_risk, set_risk] = useState(0);
  const [state_portfolio_weights, set_weight] = useState({});
  const [state_portfolio_stock_amount, set_amounts] = useState({});
  const [state_portfolio_stock_price, set_price] = useState({});
  const [state_systematic_risk, set_syst_risk] = useState(0);
  const [state_unsystematic_risk, set_unsyst_risk] = useState(0);
  const [state_var_covar_matrix, set_var_covar_matrix] = useState({});
  const [state_corr_matrix, set_corr_matrix] = useState({});
  const [state_stock_names, set_stock_names] = useState({});


  //const [statistics, set_stuff] = useState({});
  const dispatch = useDispatch();
  const store_statistics = useSelector((state) => state.statistics.PortfolioStatistics);

  // This function is the old way of solving the handleweightchange

  const handleweightchange = (key) => {
    console.log("running handle weight")
    const value = state_portfolio_stock_amount[key] * state_portfolio_stock_price[key];
    // console.log(portfolio_stock_price[key]);
    set_weight((state_weights) => ({
      ...state_weights,
      [key]: parseInt(value, 10),
    }));
  }

  // function triggered when changing the number of stocks in the statistics window
  const setPortfolioStocks = (stockSymbol, e) => {
    const value = e.target.value;

    set_amounts((prevPortfolio) => {
      // create a new constant with the updated protfolio and the new value for the stocksymbol
      const updatedPortfolio = {
        ...prevPortfolio,
        [stockSymbol]: value
      };
      // console.log(updatedPortfolio)
      // console.log(updatedPortfolio);
      return updatedPortfolio
    });
    handleweightchange(stockSymbol);
  };



  //Calculate the weights This useeffect runs whenever the portfolio_stock_amount or portfolio_stock_price and sets new weights
  useEffect(() => {
    const newWeights = {};
    Object.keys(state_portfolio_stock_amount).forEach((key) => {
      const amount = state_portfolio_stock_amount[key];
      const price = state_portfolio_stock_price[key];
      newWeights[key] = amount * price;
    });
    set_weight(newWeights);
  }, [state_portfolio_stock_amount, state_portfolio_stock_price]);

  // This useeffect runs when the statistics store object is changed
  useEffect(() => {
    console.log(store_statistics);

    // Gå ut av denne useEffecten om store_statistics ikke er satt
    if (!store_statistics || Object.keys(store_statistics).length === 0) {
      return; // Exit the useEffect early
    }

    var var_covar_matrix = {};
    var corr_matrix = {};
    var stock_names = {};
    var stock_amount = {};
    var stock_last_price = {};
    var weight_obj = {};

    var_covar_matrix = store_statistics.var_covar_matrix;
    corr_matrix = store_statistics.corr_matrix;
    stock_names = store_statistics.portfolio_stock_names;
    stock_amount = store_statistics.Stock_Amount;
    stock_last_price = store_statistics.last_stockprice;

    set_price(stock_last_price);
    set_amounts(stock_amount);
    Object.keys(stock_amount).map((key, index) => {
      weight_obj[key] = stock_amount[key] * stock_last_price[key]
    });
    set_weight(weight_obj);
    set_stock_names(stock_names);
    set_var_covar_matrix(var_covar_matrix);
    set_corr_matrix(corr_matrix);

  }, [store_statistics])

  // This useeffect is in charge of calculating the portfolio std  any time the weights, 
  useEffect(() => {
    var total_weight = 0;
    var portfolio_variance = 0
    var portfolio_deviation = 0

    if (!state_portfolio_weights || Object.keys(state_portfolio_weights).length === 0) {
      return; // Exit the useEffect early
    }

    var var_covar_matrix = {};
    var_covar_matrix = store_statistics.var_covar_matrix;

    //Find the total weight of the portfolio

    Object.values(state_portfolio_weights).map((value, index) => {
      if (value)
        total_weight = total_weight + parseInt(value, 10);
    });

    //console.log(total_weight);
    var key1_weight = 0
    var key2_weight = 0
    var var_covar_risk = 0
    var var_systematic_risk = 0
    var var_unsystematic_risk = 0

    Object.keys(var_covar_matrix).map((key, index) => {
      Object.keys(var_covar_matrix[key]).map((key2, index) => {
        //console.log(`${key}-${key2}`)
        key1_weight = isNaN(state_portfolio_weights[key]) ? 0 : ((state_portfolio_weights[key]) / total_weight);
        key2_weight = isNaN(state_portfolio_weights[key2]) ? 0 : ((state_portfolio_weights[key2]) / total_weight);
        var_covar_risk = var_covar_matrix[key][key2];
        //console.log(`Weights ${key1_weight} and ${key2_weight}`)
        //console.log(var_covar_risk)
        portfolio_variance = portfolio_variance + ((key1_weight * key2_weight) * (var_covar_risk))

        // Sum up all the systematic and unsystematic risk
        if (key == key2) {
          var_unsystematic_risk = var_unsystematic_risk + ((key1_weight * key2_weight) * (var_covar_risk))
        } else {
          var_systematic_risk = var_systematic_risk + ((key1_weight * key2_weight) * (var_covar_risk))
        }
      })
    });

    portfolio_deviation = ((Math.sqrt(portfolio_variance)) * Math.sqrt(250)).toFixed(3)

    var_systematic_risk = (var_systematic_risk / portfolio_variance) * 100
    var_unsystematic_risk = (var_unsystematic_risk / portfolio_variance) * 100

    //console.log(portfolio_deviation)
    //console.log(`Systematic risk: ${var_systematic_risk} Unsystematic risk: ${var_unsystematic_risk} PortfolioVariance: ${portfolio_variance}`)

    set_risk(portfolio_deviation);
    set_syst_risk(var_systematic_risk);
    set_unsyst_risk(var_unsystematic_risk);
  }, [state_portfolio_weights]); //End of the use effect function

  var riskTable =
    <div>
      <h2>Portfolio Standard-Deviation Calculation</h2>
      <table className="PortfolioTable">
        <thead>
          <tr>
            <th>Stock</th>
            <th>Yearly Standard Deviation</th>
            <th>Amount of Stocks</th>
            <th>Stock Price</th>
            <th>Weight/Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(state_var_covar_matrix).map((key, index1) => (
            Object.keys(state_var_covar_matrix[key]).map((key2, index2) => (
              key == key2 ? (
                <tr key={key}>
                  <td>{state_stock_names[key]}</td>
                  <td>{(Math.sqrt(state_var_covar_matrix[key][key2]) * Math.sqrt(250)).toFixed(3)}</td>
                  <td>
                    <input
                      type="number"
                      value={state_portfolio_stock_amount[key] || 0}
                      onChange={(e) => setPortfolioStocks(key, e)}
                    />
                  </td>
                  <td>
                    {state_portfolio_stock_price[key] || 0}
                  </td>
                  <td>
                    {(state_portfolio_weights[key] !== undefined && state_portfolio_weights[key] !== null) ? state_portfolio_weights[key].toFixed(0) : null}
                  </td>
                </tr>
              ) : null
            ))
          ))}
        </tbody>
      </table>

      <Container className='state_Portfolio_Risk_Container'>
        <Row lg={20}>
          <Col>
            <p className='state_Portfolio_Risk_Text'>Portfolio Standard Deviation: </p>
            <p className='state_Portfolio_Risk_Result'>{state_portfolio_risk}</p>
          </Col>
        </Row>
      </Container>
      <h3>Systematic  vs Unsystematic Risk</h3>
      <Container className='state_Portfolio_Risk_Container'>
        <Row>
          <Col>
            <p className='state_Portfolio_Risk_Text'>Systematic Risk: {state_systematic_risk.toFixed(2)}</p>
            <p className='state_Portfolio_Risk_Text'>UnSystematic Risk: {state_unsystematic_risk.toFixed(2)}</p>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <PieChart width={200} height={200}>
              <Pie
                data={[{ name: 'Systematic', value: state_systematic_risk }, { name: 'UnSystematic', value: state_unsystematic_risk }]}
                cx="50%"
                cy="50%"
                labelLine={true}
                fill="#8884d8"
                dataKey="value"
                nameKey="name"
              >
                <Cell key='Systematic' fill="#6df802"></Cell>
                <Cell key='UnSystematic' fill="#fb0000"></Cell>
              </Pie>
            </PieChart>
          </Col>
        </Row>
      </Container>
      <h2>Stock Correlation Matrix</h2>
      <table className="PortfolioTable_Corr">
        <thead>
          <tr>
            <th></th>
            {Object.keys(state_corr_matrix).map((key, index) => (
              <th>{state_stock_names[key]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(state_corr_matrix).map((key1, index1) => (
            <tr>
              <td>{state_stock_names[key1]}</td>
              {Object.keys(state_corr_matrix).map((key2, index2) => (
                <td
                  className={`Corr_${Math.round((Math.round(state_corr_matrix[key1][key2].toFixed(1) / 0.2) * 0.2) * 10)}`}
                  key={index1 + ":" + index2}
                >
                  {state_corr_matrix[key1][key2].toFixed(4)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>


  if (store_statistics == undefined) {
    console.log("No Statistics!");
    riskTable = <div>No statistics set!</div>
  }

  return riskTable
}

export default StockRisk